<template>
  <div>
    <PageTitlebar title="Manage User Account">
      <template v-slot:top>
        <Link
          :to="{ name: 'AdvertiserUserAccessOverview' }"
          label="Back to User Access"
          icon="sym_r_chevron_left"
        />
      </template>
    </PageTitlebar>

    <AdvertiserUserAccountForm
      type="edit"
      :user-id="userId"
      :submitting="submitting"
      @submit="editUser"
    />
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import Link from "@/components/UI/Link";
import AdvertiserUserAccountForm from "@/components/Advertiser/AdvertiserUserAccountForm.vue";

export default {
  name: "AdvertiserUserAccountEditor",
  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    AdvertiserUserAccountForm,
    Link,
    PageTitlebar
  },
  data() {
    return {
      submitting: false
    };
  },
  watch: {},
  mounted() {},
  methods: {
    editUser(formData) {
      this.submitting = true;

      let updateParams = {
        id: this.userId,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        type: formData.type
      };

      if (updateParams.type == "regular") {
        updateParams.advertiser_account_ids = formData.advertiserAccountIds;
      }

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "update",
          params: updateParams
        })
        .then(() => {
          this.triggerActionOutcomeAlert("save", true);
          this.backToTop();
        })
        .catch(e => {
          console.log(e);
          if (e.response) {
            this.triggerActionOutcomeAlert(
              "create",
              false,
              e.response.data.errors[0].detail
            );
          } else {
            this.triggerActionOutcomeAlert("create", false);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
